.projects-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.projects-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.projects-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}
.act-card-content {
	display: flex;
	justify-content: space-between;
}
.act-card-container {
	border-radius: 10px;
	margin-bottom: 10px;
}

.accountant {
	background: radial-gradient(circle, #aa74e1 0%, #271a33 90%);
}
.time-sheet {
	background: radial-gradient(circle, #e0a941 0%, #2a1111 90%);
}
.time-sheet-android {
	background: radial-gradient(circle, #41e059 0%, #0b3e13 90%);
}
.pms {
	/* background: radial-gradient(circle, #e0a941 0%, #70531c 90%); */
	background: radial-gradient(circle, #1e99e6 0%, #434bb4 90%);
}
.school-erp {
	/* background: radial-gradient(circle, #e0a941 0%, #70531c 90%); */
	background: radial-gradient(circle, #41e0e0 0%, #135656 90%);
}
.payment-dashboard {
	background: radial-gradient(circle, #41e093 0%, #135636 90%);
}
.buy-server {
	background: radial-gradient(circle, #b66231 0%, #2a1111 90%);
}
.cms {
	background: radial-gradient(circle, #41e0e0 0%, #135656 90%);
}
.teams-invoice {
	background: radial-gradient(circle, #706eea 0%, #271c70 90%);
}
.mdm {
	background: radial-gradient(circle, #1e99e6 0%, #434bb4 90%);
}
.student-portal {
	background: radial-gradient(circle, #41e093 0%, #135636 90%);
}
.join-zerodha {
	background: radial-gradient(circle, #41e0e0 0%, #135656 90%);
}
.fwr {
	background: radial-gradient(circle, #e0a941 0%, #70531c 90%);
}
.power-bi {
	background: radial-gradient(circle, #e0a941 0%, #70531c 90%);
}

.more-project {
	background: radial-gradient(circle, #f77c12 0%, #2a1111 90%);
}

.act-card-container:hover {
	transform: scale(1.02);
	transition: all 0.5s;
	cursor: pointer;
}
.heading {
	font-size: 30px;
	font-weight: bold;
	color: white;
	text-align: center;
}
.sub-desc {
	font-size: 15px;
	font-weight: bold;
	color: white;
	text-align: center;
}
.gradient-text {
	background: radial-gradient(circle, #2fffe7 0%, #b0f66f 90%);
}

@media (max-width: 600px) {
	.projects-container {
		padding-top: 60px;
	}

	.projects-project {
		width: calc(100% / 2);
	}
	.act-card-content {
		display: contents;
	}
}
